import React, { Component } from 'react'
import Layout from '../../components/layout'

class Empty extends Component {
  render() {
    return (
      <Layout>
        <h1>Uplne prazdna stranka</h1>
        <p>Na tjeto stranke nieje nic okrem layoutu</p>
      </Layout>
    )
  }
}

export default Empty